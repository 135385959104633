.loyalty-card {
  border: 0;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  margin: 10px 20px;
  margin-top: 20px;
  position: relative;
  height: auto;
  min-width: 70px;
  -webkit-box-shadow: -1px 0px 16px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 0px 16px -1px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 0px 16px -1px rgba(0, 0, 0, 0.75);

  .card-header {
    display: flex;
    padding: 10px 3%;
    width: 94%;
    font-size: 1.1em;
    font-family: 'Roboto', sans-serif;

    .program-name,
    .points {
      width: 50%;
    }

    .program-name {
      text-align: left;
    }

    .points {
      text-align: right;

      span {
        background: #5ac8e9;
        padding: 2px 5px;
        margin-left: 5px;
        border-radius: 10px;
      }
    }
  }

  .barcode {
    width: 50%;
    height: 40px;
    margin: auto;
    background: url('../assets/images/barcode.svg');
    background-size: cover;
    margin-bottom: -10px;
    z-index: 10;
    position: relative;
  }

  .pad {
    width: 100%;
    height: 15px;
    background: #5ac8e9;
    z-index: 1;
  }
}

.loyalty-container {
  box-shadow: 0px 0px 7px -3px;
  padding: 2px;
  margin: 8px;
  border-radius: 2%;
  max-height: 500px;

  .loyalty-download {
    font-size: 14px;
    text-align: center;
    text-decoration: underline;
    padding: 8px;
  }
}

.div-loyalty {
  position: relative;
  width: auto;
  height: auto;
  background-color: white;
  overflow: hidden;
  margin: 20px;
  padding: 20px;
  box-shadow: 0px 0px 7px -3px;
  border-radius: 2%;

  img {
    width: 30%;
    padding: 8px 16px 8px 16px;
  }

  .leftData {
    width: 50%;
    padding: 16px 0px 16px 0px;
  }
}

.div-loyalty::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 16px;
  /* Change the height to adjust the border thickness */
  background: linear-gradient(to right, #000000, #ececec, #ececec, #000000);
}

.div-loyalty::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 16px;
  /* Change the height to adjust the border thickness */
  background: linear-gradient(to right, #000000, #ececec, #ececec, #000000);
}
