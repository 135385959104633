$borderRadius100: 100%;
$defaultBgColor: #e5e5e5;
$cinepolisTicketBg: #102657;
$funCinemasTicketBg: #2f2f2f;
$BillMaxWidth: 420px;

/* latin */
/* @font-face {
    font-family: 'Roboto';
    font-display: swap;
    src: url('https://fonts.googleapis.com/css?family=Roboto:400&display=swap');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Roboto-bold';
    font-display: swap;
    font-weight: 700;
    src: url('https://fonts.googleapis.com/css?family=Roboto:700&display=swap');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
} */
@font-face {
  font-family: 'DIN';
  src:
    url('./fonts/DIN/DIN.woff2') format('woff2'),
    url('./fonts/DIN/DIN.woff') format('woff'),
    url('./fonts/DIN/DIN.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy-Light';
  src: url('https://db.onlinewebfonts.com/t/0744a43ed7aac352ec78c1404c890d72.eot');
  src:
    url('https://db.onlinewebfonts.com/t/0744a43ed7aac352ec78c1404c890d72.eot?#iefix')
      format('embedded-opentype'),
    url('https://db.onlinewebfonts.com/t/0744a43ed7aac352ec78c1404c890d72.woff2') format('woff2'),
    url('https://db.onlinewebfonts.com/t/0744a43ed7aac352ec78c1404c890d72.woff') format('woff'),
    url('https://db.onlinewebfonts.com/t/0744a43ed7aac352ec78c1404c890d72.ttf') format('truetype'),
    url('https://db.onlinewebfonts.com/t/0744a43ed7aac352ec78c1404c890d72.svg#Gilroy-Light')
      format('svg');
  font-weight: 600;
}
@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: url('https://db.onlinewebfonts.com/t/00d5a78493aed4b11e2584ad7cceee49.eot');
  src:
    url('https://db.onlinewebfonts.com/t/00d5a78493aed4b11e2584ad7cceee49.eot?#iefix')
      format('embedded-opentype'),
    url('https://db.onlinewebfonts.com/t/00d5a78493aed4b11e2584ad7cceee49.woff2') format('woff2'),
    url('https://db.onlinewebfonts.com/t/00d5a78493aed4b11e2584ad7cceee49.woff') format('woff'),
    url('https://db.onlinewebfonts.com/t/00d5a78493aed4b11e2584ad7cceee49.ttf') format('truetype'),
    url('https://db.onlinewebfonts.com/t/00d5a78493aed4b11e2584ad7cceee49.svg#Gilroy-ExtraBold')
      format('svg');
}
@font-face {
  font-family: 'DIN';
  src:
    url('./fonts/DIN/DINBold.woff2') format('woff2'),
    url('./fonts/DIN/DINBold.woff') format('woff'),
    url('./fonts/DIN/DINBold.ttf') format('ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800;900&display=swap');

html {
  font-size: 20px;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
}

body {
  height: auto;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
}

.miraj-rating {
  img {
    margin: 0 2px;
  }
}

.rawmango-body {
  font-family: 'DIN';
  font-weight: normal;
  font-style: normal;
  background-color: #fefbfa !important;
}

.bill-app {
  margin: 0 auto;
  width: 100%;
  max-width: $BillMaxWidth;
  position: relative;
  height: auto;
  min-height: 100vh;
  opacity: 1;
  background: $defaultBgColor;

  &.bk-bg {
    background-color: #6d3d2f;

    * &:not(.react-pdf__Document *) {
      color: #6d3d2f;
    }
  }

  .star-icon {
    margin: 0 3px;
  }

  .bill-app-body {
    position: relative;
    margin-top: -115px;

    .logo,
    .logo-skeleton {
      border-radius: 50%;
      max-width: 90px;
      -webkit-box-shadow: 0px 7px 15px -7px rgb(0, 0, 0);
      -moz-box-shadow: 0px 7px 15px -7px rgb(0, 0, 0);
      box-shadow: 0px 7px 15px -7px rgb(0, 0, 0);
      z-index: 100;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -40%);
      top: -30px;
      border: 2px solid #fff;
    }

    .logo-skeleton {
      min-width: 90px;
      min-height: 90px;
      background-image: linear-gradient(#ececec 100%, transparent 0);
      border: 2px solid #fff;
    }

    &.bk-body {
      margin-top: 0px;
    }

    &.bk-header {
      margin-top: -72px;

      .logo,
      .logo-skeleton {
        top: -70px;
        left: 80px;
        box-shadow: none;
        border: none;
        max-width: 120px;
      }

      .bk-trees {
        left: unset;
        right: -30px;
        border-radius: 0px;
        max-width: 150px;
        width: 100%;
      }
    }

    .bill-app-rating {
      z-index: 5;
      background: #ffffff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      width: 94%;
      margin: 0 3% 0 3%;
      height: auto;
      min-height: 30px;
      position: relative;
      text-align: center;
      padding-bottom: 10px;
      padding-top: 1em;

      .title {
        text-align: center;
        font-size: 1.3em;
        padding-top: 7%;
        font-family: 'Roboto', sans-serif;
        position: relative;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        word-break: break-all;
      }

      .bk-rating {
        font-family: 'DINPro-Medium', sans-serif;
      }

      .title-fb-submmited {
        font-size: 18px !important;
        color: #9a9797 !important;

        @media screen and (max-width: 480px) {
          font-size: 14px !important;
          text-align: center;
        }
      }

      .star-img {
        margin: 0;
        margin-top: 10px;
        width: 60%;
        max-width: 350px;
      }

      hr {
        background: #e7e7e7;
        width: 80%;
        opacity: 0.5;
        border-color: #e7e7e7;
        margin-top: 15px;
        margin-bottom: 0px;
      }
    }

    .rating-skeleton {
      margin: auto;
      width: 100%;
      height: 80px;

      background-image: linear-gradient(
          100deg,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.5) 50%,
          rgba(255, 255, 255, 0) 80%
        ),
        linear-gradient(#ececec 20px, transparent 0), linear-gradient(#ececec 20px, transparent 0);

      background-repeat: no-repeat;

      background-size:
        30% 110px,
        /* highlight */ 90% 30px,
        90% 20px;

      background-position:
        0 0,
        /* highlight */ 20px 30px,
        20px 60px;

      animation: shine 1s infinite;
    }

    @keyframes shine {
      to {
        background-position:
          100% 0,
          /* move highlight to right */ 20px 30px,
          20px 60px;
      }
    }

    .bill-img {
      width: 94%;
      margin: 0 3% 0 3%;
      background: #ffffff;
      position: relative;
      text-align: center;
      min-height: 100px;
      // height: auto;
      padding-top: 1%;
      // margin-bottom: 5%;
      padding-bottom: 5px;

      &.bill-tear {
        margin-bottom: 42px;

        &:before {
          content: '';
          position: absolute;
          bottom: -18px;
          background: url('../assets/images/bill-tear.png') repeat-x;
          width: 100%;
          height: 20px;
          display: block;
        }
      }

      .kfc-template {
        margin-top: 3em;
        padding: 0px 15px 30px;

        &,
        & * {
          font-family: monospace;
          overflow: auto;

          @media screen and (max-width: 480px) {
            font-size: 12px;
          }
        }
      }

      &:empty {
        margin: auto;
        width: 94%;
        height: 200px;
        margin-bottom: 5%;

        background-image: linear-gradient(#ececec 180px, transparent 0);

        background-repeat: no-repeat;

        background-size: 90% 200px;

        background-position: 20px 10px;
      }

      img {
        height: 100%;
      }

      img,
      iframe {
        position: relative;
        width: 96%;
        margin: 0 2%;
      }

      iframe {
        border-style: none;
      }
    }

    .devyani-group {
      padding-top: 40px;
      border-radius: 8px;
    }

    .terms-div {
      margin: 2% 2%;
      padding: 0 4%;
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-size: 0.8em;
      color: #38a1e3;
      cursor: pointer;

      span {
        text-transform: capitalize;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .bill-app .bill-app-body #ticket-template1 {
    .main-container {
      padding: 10px 15px 0px;

      .ticket-heading {
        p {
          font-size: 8px;
        }
      }

      .primary-details {
        & > div {
          p {
            font-size: 0.9em;
          }
        }
      }

      .secondary-details {
        .wrapper {
          & > div:not(#cost-breakup) {
            h4 {
              font-size: 0.9em;
            }
          }

          #cost-breakup {
            p {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .cityOfPatna {
    margin-left: 45px !important;
    margin-right: 10px !important;
  }
}

#ticket-template1 {
  // FIXME: Remove this
  // width: 1%;
  // margin: 0 5% 0 5%;
  min-height: 100px;
  height: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: transparent;
  position: relative;
  font-family: 'Roboto', sans-serif;
  // --

  // FIXME: Remove this
  .top-border {
    border-radius: 14px 14px 0px 0px;
    border: 1px solid #fff;
    border-bottom: none;
    position: relative;

    #bts-1-left {
      height: 21px;
      bottom: 0px;
      left: 0px;
      margin-left: 10px;
      margin-bottom: -5px;
      border-top-right-radius: $borderRadius100;
    }

    #bts-1-right {
      height: 21px;
      bottom: 0px;
      right: 0px;
      margin-right: 10px;
      margin-bottom: -5px;
      border-top-left-radius: $borderRadius100;
    }

    //  --
  }

  .bottom-border {
    border-radius: 0px;
    border: 1px solid #fff;
    border-top: none;
    position: relative;

    // FIXME: Remove this
    #bts-2-left {
      height: 17px;
      top: 0px;
      left: 0px;
      margin-left: 10px;
      margin-bottom: -5px;
      border-bottom-right-radius: $borderRadius100;
      margin-top: -1px;
    }

    #bts-2-right {
      height: 17px;
      top: 0px;
      right: 0px;
      margin-right: 10px;
      margin-bottom: -5px;
      border-bottom-left-radius: $borderRadius100;
      margin-top: -1px;
    }

    //  ---
  }

  // // FIXME: Remove this
  .main-container {
    // TODO: Remove main-container classname
    box-sizing: border-box;
    width: 100%;
    padding: 10px 30px 0px;
    position: relative;
    z-index: 5;
    background-color: #fff;

    @media screen and (max-width: 480px) and (min-width: 321px) {
      padding: 10px 20px 0px;
    }

    // FIXME: Remove all four
    #sw {
      position: absolute;
      left: -1px;
      bottom: -1px;
      width: 20px;
      height: 20px;
      background-color: $cinepolisTicketBg;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      border-top-right-radius: 20px;

      &.fun-bgrd-color {
        background-color: $funCinemasTicketBg;
      }

      &.miraj-bgrd-color {
        background-color: #000;
      }

      &.movieMax {
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 2px 0px inset;
      }
    }

    #se {
      position: absolute;
      right: -1px;
      bottom: -1px;
      width: 20px;
      height: 20px;
      background-color: $cinepolisTicketBg;
      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
      border-top-left-radius: 20px;

      &.fun-bgrd-color {
        background-color: $funCinemasTicketBg;
      }

      &.miraj-bgrd-color {
        background-color: #000;
      }

      &.movieMax {
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 2px 0px inset;
      }
    }

    #ne {
      position: absolute;
      right: -1px;
      top: -1px;
      width: 20px;
      height: 20px;
      background-color: $cinepolisTicketBg;
      border-bottom: 1px solid #fff;
      border-left: 1px solid #fff;
      border-bottom-left-radius: 20px;

      &.fun-bgrd-color {
        background-color: $funCinemasTicketBg;
      }

      &.miraj-bgrd-color {
        background-color: #000;
      }

      &.movieMax {
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.2) 0px -2px 2px 0px inset;
      }
    }

    #nw {
      position: absolute;
      left: -1px;
      top: -1px;
      width: 20px;
      height: 20px;
      background-color: $cinepolisTicketBg;
      border-bottom: 1px solid #fff;
      border-right: 1px solid #fff;
      border-bottom-right-radius: 20px;

      &.fun-bgrd-color {
        background-color: $funCinemasTicketBg;
      }

      &.miraj-bgrd-color {
        background-color: #000;
      }

      &.movieMax {
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.2) 0px -2px 2px 0px inset;
      }
    }

    // --

    .thank-you {
      margin-top: 12px;
      font-size: 16px;
      font-weight: 500;

      span {
        color: #e80000;
      }
    }

    .primary-details {
      // FIXME: Remove this
      background-image: linear-gradient(to right, #707070 50%, #fff 0%);
      background-position: bottom;
      background-size: 13px 1px;
      background-repeat: repeat-x;
      padding: 20px 0px 30px;
      // ----

      .address {
        .location-poster {
          display: flex;
          align-items: center;
          justify-content: space-between;

          & > img {
            width: 70px;
            height: auto;
          }
        }

        .cinepolis-primary-fields {
          margin-right: 4px;

          & * {
            font-family: 'Roboto-bold', sans-serif;
          }
        }
      }

      & > div {
        &:first-child {
          margin-bottom: 10px;

          .meta {
            padding-left: 25px;
            width: 100%;
            margin: 15px auto 0px;
            box-sizing: border-box;

            p {
              color: #787878;
              font-size: 12px;
            }
          }
        }
      }

      .movie-details {
        p {
          font-size: 0.9em;

          @media screen and (max-width: 480px) {
            font-size: 1.1em;
          }

          &:nth-child(1) {
            font-family: 'Roboto-bold', sans-serif;
            margin-bottom: 5px;
            font-weight: 600;
          }

          &:nth-child(2) {
            margin-top: 5px;
          }

          &:nth-child(3) {
            margin-top: -13px;
          }
        }
      }

      .movie-details {
        .cinepolis-primary-fields {
          margin-top: 15px;
        }
      }

      .cinepolis-primary-fields {
        display: flex;
        align-items: center;

        img {
          width: 15px;
          height: auto;
          margin-right: 10px;
        }

        p {
          margin: 0;
        }
      }

      .multicoupons {
        background: #fff9ed;
        padding: 0px 0px 5px 5px;
        margin-top: 5px;
        margin-left: -5px;
        border: 1px #f4c786 dashed;
      }
    }

    .secondary-details {
      padding: 0px;

      .wrapper {
        padding: 5px 0px;
        // background-color: #F8F8F8;
        border-radius: 10px;

        & > div:not(#cost-breakup) {
          display: flex;
          justify-content: space-between;

          h4 {
            margin: 5px 0px;
            font-size: 0.9em;

            @media screen and (max-width: 480px) and (min-width: 321px) {
              font-size: 1.1em;
            }
          }
        }

        #cost-breakup {
          p {
            margin: 0px 0px 5px;
            font-size: 12px;
          }

          div {
            display: flex;
            justify-content: space-between;

            p {
              color: #787878;
            }
          }
        }
      }
    }

    .qrcode {
      padding: 0em 0px 1.5em;
      text-align: center;
      border-bottom: 1px solid #f7f7f7;
      margin-bottom: 1em;

      .booking-id {
        margin: 1.3em 0 0;
        color: #7a8895;
      }
    }
    .miraj-qrcode {
      padding: 0em 0px 1.5em;
      text-align: center;
      border-bottom: 1px solid #f7f7f7;

      .booking-id {
        margin: 1.3em 0 0;
        color: #7a8895;
      }
    }
    .service-note {
      margin-top: 30px;

      h4 {
        color: #626262;
        margin-bottom: 10px;
        margin-top: 0px;
      }

      ul {
        padding-left: 20px;
        font-size: 12px;
      }

      p {
        text-align: center;
        color: #787878;
        font-size: 12px;
      }

      .meta {
        width: 100%;
        margin: 20px auto;

        p {
          font-size: 10px;

          &:nth-child(2) {
            margin: 0;
          }
        }
      }
    }
  }
}

.cinepolis-footer {
  width: 100%;
  position: relative;
  top: -10px;
  z-index: 10;
}

#ticket-template1 {
  // width: 92%;
  // margin: 0 4%;
  &.movieMax-temp {
    width: 100%;
    margin: 0;
  }
}

.miraj-footer {
  width: 100%;
  margin: 0;
  position: relative;
  top: -10px;
  z-index: 10;
}

.cinepolis-meta {
  display: flex;
  justify-content: center;
  gap: 4px;
  padding: 8px auto;
  flex-wrap: wrap;

  p {
    color: #787878;
    font-size: 12px !important;
  }
}
#rzpftx-body {
  margin: 10px solid #305eff;
  width: 100%;
  max-width: 420px;
  background-color: #305eff;
  background-size: 420px 100%;
  position: relative;
  font-weight: 500;
  font-family: 'Inter', sans-serif !important;
  .bill-footer {
    margin-top: 10px;
  }
  .masthead {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 35px 0;
    img {
      width: 65%;
    }
  }
  .qr-parent {
    position: relative;
  }
  .blurred-qr {
    position: relative;
    filter: blur(4px);
  }
  .blurred-alert {
    width: 95%;
    padding: 10px 20px;
    color: black;
    position: absolute;
    z-index: 10;
    // border: 2px solid #ddff6e;
    border-radius: 4px;
    // background: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    line-height: 1.5;
  }
  .event-link {
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 480px) {
    max-width: 480px;
    .bill-footer {
      max-width: 480px;
    }
  }
  .main-container {
    background-color: #fff !important;
  }

  .rzp-ftx25-header {
    margin: 0 30px 0 30px;
    display: flex;
    background-color: #ddff6e;
    align-items: center;
    padding: 20px;
  }
  .invoiceclass {
    font-weight: bold;
    text-align: right;
    font-size: 22px;
  }
  .bill-app-body {
    margin: 0 30px;
    margin-bottom: 20px;
  }
  .header {
    width: 100%;
    position: relative;
    z-index: 4;

    .cinepolis-hr {
      font-size: 1rem;
      color: #fff;
      font-weight: 600;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -180px;
      background: unset;
      width: 100%;
      height: 300px;
      display: block;
      z-index: -1;
    }

    img {
      width: 60%;
      max-width: 240px;
    }

    h2 {
      margin: 10px auto 0px;
      width: 80%;
      color: #6591dd;
      font-weight: 500;
    }
  }

  .footermargin {
    margin-top: 80px;
  }
  .cine_rating-container {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 10;
    .bill-app-rating {
      text-align: center;
    }
    .title {
      text-align: center;
      position: relative;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 5px;
      word-break: break-all;

      .cinepolis-ticket-rating {
        font-size: 16px;
        color: rgb(105, 105, 105);
      }
    }

    .title-fb-submmited {
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;

      @media screen and (max-width: 480px) {
        text-align: center;
      }
    }
  }
}

// Cinepolis body start
// ==================================================

#cinepolis-body {
  margin: 0 auto;
  width: 100%;
  max-width: 420px;
  background-color: #102657;
  padding-bottom: 5%;
  position: relative;

  &.cineFoodBgrd {
    background: url('../assets/images/cinepolis-bgrd.jpg') top no-repeat;
    .header {
      &:after {
        content: none;
      }
    }
  }

  &.funcinemas-body {
    background: $funCinemasTicketBg url('../assets/images/fun-cinemas/fun-bgrd.svg') top no-repeat;
    background-size: contain;

    .header {
      &:after {
        content: none;
      }

      h2 {
        color: #fff;
      }
    }
  }

  .header {
    padding: 20px 0px;
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 4;

    &:after {
      content: '';
      position: absolute;
      bottom: -180px;
      background: -webkit-gradient(
          linear,
          left top,
          left bottom,
          color-stop(50%, transparent),
          color-stop(50%, #102657)
        ),
        radial-gradient(circle at bottom, #1167af 30%, #103971 61%, transparent 75%);
      background:
        -o-linear-gradient(top, transparent 50%, #102657 50%),
        -o-radial-gradient(bottom, circle, #1167af 30%, #103971 61%, transparent 75%);
      background: linear-gradient(to bottom, transparent 50%, #102657 50%),
        radial-gradient(circle at bottom, #1167af 30%, #103971 61%, transparent 75%);
      width: 100%;
      height: 300px;
      display: block;
      z-index: -1;
    }

    img {
      width: 60%;
      max-width: 200px;
    }

    h2 {
      margin: 10px auto 0px;
      width: 80%;
      color: #6591dd;
      font-weight: 400;
    }
  }

  .cinepolis__rating-container {
    display: flex;
    justify-content: center;
    background-color: #fff;
    width: 92%;
    margin: 0px 4%;
    border-radius: 14px;
    padding: 10px 0px;
    position: relative;
    top: 10px;
    z-index: 10;
    box-shadow: 0px 4px 5px hsla(0, 0%, 60%, 0.6);

    .bill-app-rating {
      text-align: center;
    }

    .title {
      text-align: center;
      font-size: 1.3em;
      font-family: 'Roboto', sans-serif;
      position: relative;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 5px;
      word-break: break-all;
    }

    .title-fb-submmited {
      font-size: 18px !important;
      color: #9a9797 !important;

      @media screen and (max-width: 480px) {
        font-size: 14px !important;
        text-align: center;
      }
    }
  }
}
#goColors-body {
  border: 2px solid #dadee7;
  background-color: #dadee7;
}
#goColors-body {
  border: 2px solid #dadee7;
  background-color: #dadee7;
}
#manyavar-body {
  border: 2px solid #feecdb;
  background-color: #feecdb;
}
.wowmomos-bg {
  margin: 0 auto;
  max-width: 420px;
  width: 100%;
  background-image: url('../assets/images/wowMomos/bg.jpg');
  background-size: 100%;
  background-repeat: repeat;
}
.himalayawellness-bg {
  margin: 0 auto;
  max-width: 420px;
  width: 100%;
  background-color: #02797a;
  background-repeat: repeat;
}
.wowfranchise-bg {
  margin: 0 auto;
  max-width: 420px;
  width: 100%;
  background-size: 100%;
  background-repeat: repeat;
}
.wowchina-bg {
  background-image: url('../assets/images/wow-china/bg.png');
}
.wowchicken-bg {
  background-image: url('../assets/images/wow-chicken/bg.png');
}
.wowkulfi-bg {
  background-image: url('../assets/images/wow-kulfi/bg.png');
}
.sammm-bg {
  margin: 0 auto;
  max-width: 420px;
  width: 100%;
  background-image: url('../assets/images/Sammm/sammm-bill-bg.jpg');
  background-size: 100%;
  background-repeat: repeat;
}
#nycine-body {
  margin: 0 auto;
  width: 100%;
  max-width: 420px;
  // padding-bottom: 5%;
  position: relative;
  border: 12px solid #013b49;
  .bill-app-body {
    border: 1px solid black;
    background-color: #e7f5ee;
    border-radius: 15px;
  }
  .media-no-print-nycine {
    background-color: #013b49;
    color: white;
    width: 101%;
    border-radius: 10px;
    margin: -2px;
    span {
      width: 20%;
      float: left;
      img {
        width: 86%;
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
  .feedback-heart {
    width: 50% !important;
    span {
      width: 20%;
      float: left;
      img {
        width: 86%;
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
  .header {
    // padding: 16px 0px;
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 4;
  }
}
.ticket-bg {
  background-image: url('../assets/images/connplex/connplex-movie-ticket-bg.jpg');
  background-size: 220%;
}
.food-bg {
  background-image: url('../assets/images/connplex/connplex-food-bill-bg.jpg');
  background-size: 100%;
}
#connplex-body1 {
  margin: 0 auto;
  width: 100%;
  max-width: 420px;
  // padding-bottom: 5%;
  position: relative;

  .connplexhowlike {
    text-transform: uppercase;
    text-align: center;
    font-family: roboto;
  }
  .media-no-print {
    display: flex;
    position: relative;
    width: 38%;
    span {
      width: 20%;
      float: left;
      img {
        width: 100%;
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
  .footerbtntc {
    background: transparent;
    border: 2px solid #333;
    margin-left: 80px;
    width: 60%;
    margin-bottom: -18px;
    font-weight: bold;
    font-family: futura;
    font-size: 10px;
  }
  .footerbtnnh {
    width: 56%;
    background: transparent;
    border: 2px solid #333;
    margin-left: 23%;
    font-weight: bold;
    font-family: futura;
    font-size: 10px;
  }
  .footerstyl {
    font-family: futura;
    font-size: 17px;
    font-style: italic;
    margin-left: 73px;
    margin-bottom: -4%;
  }
  .hLffLt {
    //check
    padding: 10px 0;
    background: transparent;
    margin-bottom: -41px;
    p {
      color: black;
      font-family: roboto;
      font-size: 16px;
      text-align: center;
    }
  }
  .Socialalign {
    width: 55%;
    margin-left: 94px;
    margin-top: -14px;
    margin-bottom: -16px;
  }
  .connplextitle {
    font-size: 15px;
    text-align: center;
    margin-top: 15px;
    color: #000;
    font-family: Futura;
    text-transform: uppercase;
    margin: 5px;
  }
  .sc-dAbbOL gqLLpI {
    width: 60%;
    margin-left: 82px;
  }
  .star-icon-connplex {
    width: 50%;
    // margin: 0 2px;
    margin-left: 0;
  }
  .qrcode {
    font-family: futura;
    font-size: 16px;
    font-style: italic;
  }
  .connplex-gst {
    font-family: futura;
    font-size: 12px;
    font-style: italic;
    text-align: center;
    font-weight: bold;
    margin-top: 35px;
  }
  .miraj__rating-container {
    display: flex;
    justify-content: center;
    // background-color: #fff;
    width: 92%;
    margin: 0px 4%;
    // border-radius: 14px;
    padding: 10px 0px;
    position: relative;
    top: 10px;
    z-index: 10;
    // box-shadow: 0px 4px 5px hsla(0, 0%, 60%, 0.6);

    .bill-app-rating {
      text-align: center;
      background-color: none;
      margin-bottom: 10px;
    }

    .title {
      text-align: center;
      font-size: 1.3em;
      font-family: 'Roboto', sans-serif;
      position: relative;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 5px;
      word-break: break-all;
    }

    .title-fb-submmited {
      font-size: 18px !important;
      color: rgb(8, 8, 8) !important;

      @media screen and (max-width: 480px) {
        font-size: 14px !important;
        text-align: center;
      }
    }
  }
  .connplex-layoutset {
    z-index: 20;
    left: 40%;
    border-radius: 95%;
    width: 73px;
    height: 60px;
    position: absolute;
    margin: auto;
    top: -9px;
    background-image: url('../assets/images/connplex/connplex-movie-ticket-bg.jpg');
    background-position: center;
  }
  .connplex-digital {
    text-align: center !important;
    width: 100%;
  }

  .header {
    // padding: 16px 0px;
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 4;
  }
}
#hdfc-body {
  margin: 0 auto;
  width: 100%;
  max-width: 420px;
  background-color: white;
  padding-bottom: 5%;
  position: relative;
  min-width: auto;
}
// .miraj-maxwidth{
// 	max-width: 440px !important;
// }
#decathind-body {
  font-family: 'Inter';
}
#miraj-body {
  margin: 0 auto;
  width: 100%;
  max-width: 420px;
  background-color: #000;
  position: relative;
  background-repeat: no-repeat;
  min-width: auto;

  .header {
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 4;

    img.miraj-logo {
      width: 62px;
      margin-top: 6px;
    }

    img.miraj-hr {
      height: 5px;
      max-width: 100%;
      margin-top: 10px;
    }
  }

  .miraj__rating-container {
    display: flex;
    justify-content: center;
    background-color: #fff;
    width: 92%;
    margin: 0px 4%;
    padding: 10px 0px;
    position: relative;
    z-index: 10;
    box-shadow: 0px 4px 5px hsla(0, 0%, 60%, 0.6);

    .bill-app-rating {
      text-align: center;
    }

    .title {
      text-align: center;
      font-size: 1.3em;
      font-family: 'Roboto', sans-serif;
      position: relative;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 5px;
      word-break: break-all;
    }

    .title-fb-submmited {
      font-size: 18px !important;
      color: #9a9797 !important;

      @media screen and (max-width: 480px) {
        font-size: 14px !important;
        text-align: center;
      }
    }
  }

  miraj-qrcode {
    padding: 0em 0px 1.5em;
    text-align: center;
    border-bottom: 1px solid #f7f7f7;
    margin-bottom: 1em;

    .booking-id {
      margin: 1.3em 0 0;
      color: #7a8895;
    }
  }
}

#carnival-body {
  margin: 0 auto;
  width: 100%;
  max-width: 420px;
  background-color: #000;
  padding-bottom: 5%;
  position: relative;

  .header {
    // padding: 16px 0px;
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 4;

    img.miraj-logo {
      width: 80px;
    }

    img.miraj-hr {
      height: 5px;
      max-width: 100%;
      margin-top: 10px;
    }
  }

  .miraj__rating-container {
    display: flex;
    justify-content: center;
    background-color: #fff;
    width: 92%;
    margin: 0px 4%;
    border-radius: 14px;
    padding: 10px 0px;
    position: relative;
    top: 10px;
    z-index: 10;
    box-shadow: 0px 4px 5px hsla(0, 0%, 60%, 0.6);

    .bill-app-rating {
      text-align: center;
    }

    .title {
      text-align: center;
      font-size: 1.3em;
      font-family: 'Roboto', sans-serif;
      position: relative;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 5px;
      word-break: break-all;
    }

    .title-fb-submmited {
      font-size: 18px !important;
      color: #9a9797 !important;

      @media screen and (max-width: 480px) {
        font-size: 14px !important;
        text-align: center;
      }
    }
  }
}
#connplex-body {
  margin: 0 auto;
  width: 100%;
  max-width: 420px;
  background-color: #231f20;
  padding-bottom: 5%;
  position: relative;

  .miraj__rating-container {
    display: flex;
    justify-content: center;
    background-color: #fff;
    width: 92%;
    margin: 0px 4%;
    border-radius: 14px;
    padding: 10px 0px;
    position: relative;
    top: 10px;
    z-index: 10;
    box-shadow: 0px 4px 5px hsla(0, 0%, 60%, 0.6);

    .bill-app-rating {
      text-align: center;
    }

    .title {
      text-align: center;
      font-size: 1.3em;
      font-family: 'Roboto', sans-serif;
      position: relative;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 5px;
      word-break: break-all;
    }

    .title-fb-submmited {
      font-size: 18px !important;
      color: #9a9797 !important;

      @media screen and (max-width: 480px) {
        font-size: 14px !important;
        text-align: center;
      }
    }
  }
}

#prasad-body {
  margin: 0 auto;
  width: 100%;
  max-width: 420px;
  // background: transparent radial-gradient(closest-side at 50% 50%, #000000 0%, #29156E 100%) 0% 0% no-repeat padding-box;
  background: url('../assets/images/prasad/ticket_background.jpg') no-repeat;
  background-size: cover;
  padding-bottom: 5%;
  position: relative;

  .miraj__rating-container {
    display: flex;
    justify-content: center;
    background-color: #fff;
    width: 92%;
    margin: 0px 4%;
    border-radius: 14px;
    padding: 10px 0px;
    position: relative;
    top: 10px;
    z-index: 10;
    box-shadow: 0px 4px 5px hsla(0, 0%, 60%, 0.6);

    .bill-app-rating {
      text-align: center;
    }

    .title {
      text-align: center;
      font-size: 1.3em;
      font-family: 'Roboto', sans-serif;
      position: relative;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 5px;
      word-break: break-all;
    }

    .title-fb-submmited {
      font-size: 18px !important;
      color: #9a9797 !important;

      @media screen and (max-width: 480px) {
        font-size: 14px !important;
        text-align: center;
      }
    }
  }
}

.multicoupons {
  background: #fff9ed;
  padding: 0px 0px 5px 5px;
  margin-top: 5px;
  margin-left: -5px;
  border: 1px #f4c786 dashed;
}

.star-custom-icon {
  width: 50% !important;
  span {
    width: 20%;
    // float: inline-start;
    img {
      width: 86%;
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.vertical-top {
  vertical-align: 'top';
}
.disclaimerText {
  text-align: center;
  white-space: pre-line;
}
// Cinepolis body end
// ==================================================
.dashMargin {
  margin-top: 28px !important;
}
.movieimg {
  height: auto;
  margin-right: 10px;
  width: 15px;
}
.movietxt-forimg {
  align-items: center;
  margin-top: 15px;
  padding: 0px 8px;
}

@media screen {
  .onlyPrint {
    display: none;
  }
}

.GoColorsTnC {
  background-color: black !important;
  color: white !important;
  border-radius: 10px;
  text-decoration: none !important;
  padding: 9px 20px !important;
  font-size: 13px !important;
}

.manyavarTnC {
  background-color: #feecdb !important;
  font-weight: 600;
}
.wowmomosTnc {
  background-color: transparent !important;
  font-weight: 600;
}
.wowFranchiseTnc {
  color: white;
}
.manyavar-bill-tear {
  margin-left: 18px;
  width: 91px;
}
.billTearCustomStyle {
  width: 91%;
  margin: -5px;
  margin-left: 19px;
}
.billTearStyle {
  width: 100%;
}

.manyavarViewLess {
  margin-bottom: -15px;
}

.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.sammm-header {
  margin: 40px;
}
//Wow Momos
.wowmomos-header {
  width: 100%;
}
.rajhans-foodmenu-text {
  font-weight: 600;
  font-size: 0.9em;
  text-decoration: none;
  cursor: pointer;
  border: 4px solid #000000;
  padding: 5px;
  border-radius: 10px;
  background-color: #000000;
  color: #f5f5f5;
}
.manyavar-footer {
  display: flex;
  justify-content: center;
}
.manyavar-need-help {
  background-color: #4c2008 !important;
  color: #f46f23 !important;
}
.manyavar-need-help-bgclr {
  background-color: #feecdb !important;
}
.text-font-align {
  text-align: center !important;
  font-size: 13px !important;
}
.taxes-Font {
  font-size: 14px !important;
  font-weight: 550;
}
.adjust-Width {
  width: 40%;
}
.text-left {
  text-align: left !important;
}
.verticalTopandLeftalign {
  text-align: left !important;
  width: 40%;
  vertical-align: top;
}
.vertical-Top {
  vertical-align: top;
}
.highlighter-Oval {
  display: inline-block;
  padding: 10px 50px;
  border: 2px solid black;
  border-radius: 70%;
  text-align: center;
  margin: 10px auto;
}
.himalaya-logo {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.himalaya-headerImg {
  position: relative;
  text-align: center;
  background-image: url('../assets/images/himalaya-wellness/himalaya-wellness-bg-logo.jpg');
  background-size: cover;
  background-position: center;
  height: 150px; // Adjust height as needed
  display: flex;
  justify-content: center;
  align-items: center;
}
